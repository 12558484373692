import {
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  startServerRequest,
} from '@gts-common/client';
import { serverComm } from '@gts-ns/ui';

import {
  ConditionalLink,
  WIDGET_SURVEY,
  WidgetGetSurveyResponseConditionalQuestion,
  WidgetAnswerSurveyRequestBody,
} from '@gts-ns/utils';
import {
  ClientAnswerTypeAnswer,
  SurveyAnswer,
  SurveyWidgetStatus,
} from '../types';
import {
  DISPLAY_ANSWERED,
  DISPLAY_CONDITIONAL_LINK,
  DISPLAY_CONDITIONAL_QUESTION,
  DisplayAnswered,
  DisplayConditionalLinkAction,
  DisplayConditionalQuestionAction,
  Thunk,
} from './reduxActionTypes';

function displayConditionalQuestion(
  conditionalQuestion: WidgetGetSurveyResponseConditionalQuestion,
  mainQuestionAnswer: SurveyAnswer,
): DisplayConditionalQuestionAction {
  return {
    type: DISPLAY_CONDITIONAL_QUESTION,
    payload: {
      conditionalQuestion,
      mainQuestionAnswer,
    },
  };
}

function displayConditionalLink(
  conditionalLink: ConditionalLink,
): DisplayConditionalLinkAction {
  return {
    type: DISPLAY_CONDITIONAL_LINK,
    payload: conditionalLink,
  };
}

function displayAnswered(): DisplayAnswered {
  return {
    type: DISPLAY_ANSWERED,
  };
}

export function execSendAnswer(answer: SurveyAnswer | undefined): Thunk {
  return (dispatch, getState) => {
    const state = getState();
    const mainQuestionAnswer = state.app.mainQuestionAnswer;

    dispatch(displayAnswered());

    // If we don't have any value in the mainQuestionAnswer then answer is the main question answer
    // otherwise it is the conditional question answer (could be undefined if the conditional question was canceled)
    let dataToSend: WidgetAnswerSurveyRequestBody;

    if (mainQuestionAnswer && answer) {
      dataToSend = {
        ...mainQuestionAnswer,
        conditionalQuestionId: answer.questionId,
        conditionalAnswerTypeAnswerId: answer.answerTypeAnswerId,
      };
    } else if (mainQuestionAnswer) {
      dataToSend = mainQuestionAnswer;
    } else if (answer) {
      dataToSend = answer;
    } else {
      throw new Error('No answer given');
    }

    dispatch(startServerRequest());

    // Don't inform the user if something goes wrong. It doesn't really matter if an answer gets lost
    serverComm
      .execPostRequest<Record<string, never>, WidgetAnswerSurveyRequestBody>(
        `${WIDGET_SURVEY}/${window.location.search}`,
        dataToSend,
      )
      .then(
        () => {
          dispatch(serverRequestSucceeded());
        },
        () => {
          dispatch(serverRequestFailed());
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
      });
  };
}

export function tryToSendAnswer(
  questionId: string,
  answerTypeAnswerId: string,
): Thunk {
  return (dispatch, getState) => {
    const state = getState();
    const surveyWidgetStatus = state.app.surveyWidgetStatus;
    const activeQuestion = state.app.activeQuestion;

    const givenAnswer = { answerTypeAnswerId, questionId };

    // If we are already displaying a conditional question then we are done here.
    // If we are displaying the main question we need to figure out if we have a conditional question or a link
    if (surveyWidgetStatus === SurveyWidgetStatus.CONDITIONAL_QUESTION) {
      return dispatch(execSendAnswer(givenAnswer));
    }

    if (!activeQuestion) {
      throw new Error('Active question must be defined here');
    }

    const questionAnswers = activeQuestion.answers;

    const answer = questionAnswers.find(
      (answer) => answer.answerTypeAnswerId === answerTypeAnswerId,
    ) as ClientAnswerTypeAnswer;
    const { conditionalQuestion, conditionalLink } = answer;

    if (conditionalQuestion) {
      dispatch(displayConditionalQuestion(conditionalQuestion, givenAnswer));
    } else if (conditionalLink) {
      // If we have a conditional link then just answer the main question. Nothing more to do
      dispatch(execSendAnswer(givenAnswer));
      dispatch(displayConditionalLink(conditionalLink));
    } else {
      dispatch(execSendAnswer(givenAnswer));
    }
  };
}

import {
  Box,
  createStyles,
  Grid,
  Theme,
  WithStyles,
  withStyles,
  WithWidth,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import { thumbs as colors } from '@gts-ns/ui';
import withWidth from '@material-ui/core/withWidth';
import { AnswerTypeComponentProps } from '../../types';

const styles = (theme: Theme) =>
  createStyles({
    answerType: {
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        fontSize: 90,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 120,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 200,
      },
    },
  });

function getRelativeOffset(width: WithWidth['width']) {
  switch (width) {
    case 'xs':
      return '0.85rem';
    case 'sm':
      return '1.1rem';
    case 'md':
      return '2rem';
    case 'lg':
      return '2rem';
    case 'xl':
      return '2rem';
    default:
      return '0';
  }
}

type AnswerTypeThumbsProps = AnswerTypeComponentProps &
  WithStyles<typeof styles> &
  WithWidth;

const UnstyledAnswerTypeThumbs = ({
  sendAnswer,
  questionId,
  answerTypeAnswers,
  classes,
  width,
  noSelect,
}: AnswerTypeThumbsProps) => {
  const thumbUpAnswer = answerTypeAnswers[1];
  const thumbDownAnswer = answerTypeAnswers[0];

  return (
    <Grid
      container
      spacing={3}
      style={{ textAlign: 'center' }}
      justify="center"
    >
      <Grid item xs={6} key={thumbUpAnswer.answerTypeAnswerId}>
        <FontAwesomeIcon
          className={`${classes.answerType} ${noSelect}`}
          icon={faThumbsUp}
          color={colors(1)}
          onClick={() =>
            sendAnswer(questionId, thumbUpAnswer.answerTypeAnswerId)
          }
          onKeyPress={() =>
            sendAnswer(questionId, thumbUpAnswer.answerTypeAnswerId)
          }
        />
      </Grid>
      <Grid item xs={6} key={thumbDownAnswer.answerTypeAnswerId}>
        <Box p={getRelativeOffset(width)} />
        <FontAwesomeIcon
          className={`${classes.answerType} ${noSelect}`}
          icon={faThumbsDown}
          color={colors(0)}
          onClick={() =>
            sendAnswer(questionId, thumbDownAnswer.answerTypeAnswerId)
          }
          onKeyPress={() =>
            sendAnswer(questionId, thumbDownAnswer.answerTypeAnswerId)
          }
        />
      </Grid>
    </Grid>
  );
};

export const AnswerTypeThumbs = withWidth()(
  withStyles(styles)(UnstyledAnswerTypeThumbs),
);

import {
  ID_REGEX,
  IndexSignatureHack,
  ServerResponseError,
  ServerResponseErrorType,
} from '@gts-common/client-server';
import { serverComm } from '@gts-ns/ui';
import {
  getErrorModalMessage,
  getQueryParameterByName,
  KnownModalMessage,
  MessageType,
  ModalType,
  serverRequestFailed,
  serverRequestSucceeded,
  startServerRequest,
  invalidLinkMessage,
  unknownLinkMessage,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  WIDGET_SURVEY,
  WidgetGetSurveyRequestQuery,
  WidgetGetSurveyResponse,
} from '@gts-ns/utils';
import { serverStatusErrors } from '../validationMessages';
import {
  GET_SURVEY_SUCCEEDED,
  GetSurveySucceededAction,
  LINK_INVALID,
  LinkInvalidAction,
  SURVEY_NOT_FOUND,
  SURVEY_NOT_ACTIVE,
  SurveyNotFoundAction,
  SurveyNotActiveAction,
  Thunk,
} from './reduxActionTypes';

function getSurveySucceeded(
  data: WidgetGetSurveyResponse,
): GetSurveySucceededAction {
  return {
    type: GET_SURVEY_SUCCEEDED,
    payload: data,
  };
}

function surveyNotFound(): SurveyNotFoundAction {
  return {
    type: SURVEY_NOT_FOUND,
  };
}

function linkInvalid(): LinkInvalidAction {
  return {
    type: LINK_INVALID,
  };
}

function surveyInactive(): SurveyNotActiveAction {
  return {
    type: SURVEY_NOT_ACTIVE,
  };
}

function isTokenValid() {
  const value = getQueryParameterByName('sid');

  if (value === null) {
    return false;
  } else if (value === '') {
    return false;
  } else {
    return ID_REGEX.test(value);
  }
}

const invalidLinkModal: KnownModalMessage = {
  type: MessageType.MODAL,
  modalType: ModalType.ERROR_MODAL,
  title: 'Link ungültig',
  body: invalidLinkMessage,
};

const restaurantNotFoundModal: KnownModalMessage = {
  type: MessageType.MODAL,
  modalType: ModalType.ERROR_MODAL,
  title: 'Link unbekannt',
  body: unknownLinkMessage,
};

const surveyNotActiveModal: KnownModalMessage = {
  type: MessageType.MODAL,
  modalType: ModalType.ERROR_MODAL,
  title: 'Umfrage inaktiv',
  body: serverStatusErrors[1000],
};

export function getSurvey(): Thunk {
  return (dispatch) => {
    if (!isTokenValid()) {
      dispatch(linkInvalid());
      return dispatch(serverRequestFailed(invalidLinkModal));
    }

    dispatch(startServerRequest());

    // Token validity was checked above so it must be a string here
    const sid = getQueryParameterByName('sid') as string;

    const query: WidgetGetSurveyRequestQuery = { sid };

    return serverComm
      .execGetRequest<
        WidgetGetSurveyResponse,
        IndexSignatureHack<WidgetGetSurveyRequestQuery>
      >(WIDGET_SURVEY, query)
      .then(
        (resp) => {
          if (resp.succeeded) {
            const { body } = resp;

            dispatch(getSurveySucceeded(body));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(surveyInactive());
            dispatch(serverRequestFailed(surveyNotActiveModal));
          }
        },
        (e: unknown) => {
          if (e instanceof ServerResponseError) {
            if (e.type === ServerResponseErrorType.NOT_FOUND) {
              dispatch(surveyNotFound());
              return dispatch(serverRequestFailed(restaurantNotFoundModal));
            }
          }

          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

import { custom } from '@gts-ns/ui';

import {
  Grid,
  Box,
  Typography,
  withStyles,
  withWidth,
  GridSize,
  Theme,
  createStyles,
  WithStyles,
  WithWidth,
} from '@material-ui/core';
import { AnswerTypeComponentProps } from '../../types';

const styles = (theme: Theme) =>
  createStyles({
    text: {
      fontWeight: 'bold',
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 22,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 26,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 28,
      },
    },
  });

const columns = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 5,
  xl: 5,
};

type AnswerTypeCustomProps = AnswerTypeComponentProps &
  WithStyles<typeof styles> &
  WithWidth;

const UnstyledAnswerTypeCustom = ({
  sendAnswer,
  questionId,
  answerTypeAnswers,
  classes,
  noSelect,
  width,
}: AnswerTypeCustomProps) => {
  const colors = custom(answerTypeAnswers.length);
  const maxColumns = columns[width];
  const gridItemWidth = Math.floor(
    12 / Math.min(answerTypeAnswers.length, maxColumns),
  ) as GridSize;
  const height = Math.floor(40 / answerTypeAnswers.length);

  return (
    <Grid container spacing={1}>
      {answerTypeAnswers.map((answerTypeAnswer, i) => (
        <Grid item xs={gridItemWidth} key={answerTypeAnswer.answerTypeAnswerId}>
          <Box
            className={`${noSelect}`}
            pt={`${height / 2}rem`}
            pb={`${height / 2}rem`}
            border={1}
            borderRadius={5}
            borderColor="grey.500"
            bgcolor={colors(i)}
            style={{
              cursor: 'pointer',
            }}
            key={answerTypeAnswer.answerTypeAnswerId}
            onClick={() =>
              sendAnswer(questionId, answerTypeAnswer.answerTypeAnswerId)
            }
            onKeyPress={() =>
              sendAnswer(questionId, answerTypeAnswer.answerTypeAnswerId)
            }
            role="link"
            textAlign="center"
            height="100%"
          >
            <Typography className={`${noSelect} ${classes.text}`}>
              {answerTypeAnswer.label}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export const AnswerTypeCustom = withWidth()(
  withStyles(styles)(UnstyledAnswerTypeCustom),
);

import { ReduxState, SurveyWidgetStatus } from './types';

export const initialState: Omit<ReduxState, 'common'> = {
  app: {
    survey: undefined,
    activeQuestion: undefined,
    conditionalLink: undefined,
    surveyWidgetStatus: SurveyWidgetStatus.PENDING,
    mainQuestionAnswer: undefined,
  },
};

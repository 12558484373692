import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { CommonActions } from '@gts-common/client';
import {
  ConditionalLink,
  WidgetGetSurveyResponseConditionalQuestion,
  WidgetGetSurveyResponse,
} from '@gts-ns/utils';
import { ReduxState, SurveyAnswer } from '../types';

export const GET_SURVEY_SUCCEEDED = 'GET_SURVEY_SUCCEEDED';
export const SURVEY_NOT_FOUND = 'SURVEY_NOT_FOUND';
export const SURVEY_NOT_ACTIVE = 'SURVEY_NOT_ACTIVE';
export const LINK_INVALID = 'LINK_INVALID';

export const DISPLAY_CONDITIONAL_QUESTION = 'DISPLAY_CONDITIONAL_QUESTION';
export const DISPLAY_CONDITIONAL_LINK = 'DISPLAY_CONDITIONAL_LINK';
export const DISPLAY_ANSWERED = 'DISPLAY_ANSWERED';

export interface GetSurveySucceededAction extends Action {
  type: typeof GET_SURVEY_SUCCEEDED;
  payload: WidgetGetSurveyResponse;
}

export interface SurveyNotFoundAction extends Action {
  type: typeof SURVEY_NOT_FOUND;
}

export interface SurveyNotActiveAction extends Action {
  type: typeof SURVEY_NOT_ACTIVE;
}

export interface LinkInvalidAction extends Action {
  type: typeof LINK_INVALID;
}

export interface DisplayConditionalQuestionAction extends Action {
  type: typeof DISPLAY_CONDITIONAL_QUESTION;
  payload: {
    conditionalQuestion: WidgetGetSurveyResponseConditionalQuestion;
    mainQuestionAnswer: SurveyAnswer;
  };
}

export interface DisplayConditionalLinkAction extends Action {
  type: typeof DISPLAY_CONDITIONAL_LINK;
  payload: ConditionalLink;
}

export interface DisplayAnswered extends Action {
  type: typeof DISPLAY_ANSWERED;
}

export type Actions =
  | GetSurveySucceededAction
  | SurveyNotFoundAction
  | SurveyNotActiveAction
  | LinkInvalidAction
  | DisplayConditionalQuestionAction
  | DisplayConditionalLinkAction
  | DisplayAnswered
  | CommonActions;

export type Thunk = ThunkAction<void, ReduxState, unknown, Actions>;

import { SimpleHeader, MessagesContainer, Footer } from '@gts-common/client';
import { Container } from '@material-ui/core';
import {
  ConditionalLink,
  WidgetGetSurveyResponse,
  WidgetGetSurveyResponseConditionalQuestion,
} from '@gts-ns/utils';
import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';
import { QuestionType, SurveyWidgetStatus } from '../types';
import logoSrc from '../images/logo.png';

import { getMainView } from './getMainView';

interface Props {
  survey: WidgetGetSurveyResponse | undefined;
  activeQuestion:
    | QuestionType
    | WidgetGetSurveyResponseConditionalQuestion
    | undefined;
  conditionalLink: ConditionalLink | undefined;
  surveyWidgetStatus: SurveyWidgetStatus;
  tryToSendAnswer: (questionId: string, answerTypeAnswerId: string) => void;
  cancelConditionalQuestion: () => void;
}

export const App = ({
  survey,
  activeQuestion,
  conditionalLink,
  surveyWidgetStatus,
  tryToSendAnswer,
  cancelConditionalQuestion,
}: Props) => {
  return (
    <>
      <SimpleHeader title="next-survey" logoSrc={logoSrc} />
      {/* bottom margin is used to offset the footer height */}
      <Container style={{ marginBottom: '80px' }}>
        {getMainView(
          surveyWidgetStatus,
          survey,
          activeQuestion as QuestionType,
          conditionalLink,
          tryToSendAnswer,
          cancelConditionalQuestion,
        )}
      </Container>
      <Footer domain={getUrl(ServiceCode.NEXT_SURVEY, UrlType.FRONT_PAGE)} />
      <MessagesContainer />
    </>
  );
};

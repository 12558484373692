import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { App } from '../components/App';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { execSendAnswer, tryToSendAnswer } from '../actions';

const mapStateToProps = (state: ReduxState) => {
  return {
    survey: state.app.survey,
    activeQuestion: state.app.activeQuestion,
    conditionalLink: state.app.conditionalLink,
    surveyWidgetStatus: state.app.surveyWidgetStatus,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  tryToSendAnswer(questionId: string, answerTypeAnswerId: string) {
    dispatch(tryToSendAnswer(questionId, answerTypeAnswerId));
  },
  // User didn't want to answer the conditional question
  // Just send the answer for the main question
  cancelConditionalQuestion() {
    dispatch(execSendAnswer(undefined));
  },
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

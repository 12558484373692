import {
  createStyles,
  Grid,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faMeh, faSmile } from '@fortawesome/free-solid-svg-icons';
import { smileys as colors } from '@gts-ns/ui';
import { AnswerTypeComponentProps } from '../../types';

const styles = (theme: Theme) =>
  createStyles({
    answerType: {
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        fontSize: 100,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 120,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 200,
      },
    },
  });

type AnswerTypeSmileyProps = AnswerTypeComponentProps &
  WithStyles<typeof styles>;

const UnstyledAnswerTypeSmiley = ({
  sendAnswer,
  questionId,
  answerTypeAnswers,
  classes,
  noSelect,
}: AnswerTypeSmileyProps) => (
  <Grid container spacing={3} style={{ textAlign: 'center' }} justify="center">
    <Grid item xs={4} key={answerTypeAnswers[0].answerTypeAnswerId}>
      <FontAwesomeIcon
        className={`${classes.answerType} ${noSelect}`}
        icon={faSmile}
        color={colors(0)}
        onClick={() =>
          sendAnswer(questionId, answerTypeAnswers[0].answerTypeAnswerId)
        }
        onKeyPress={() =>
          sendAnswer(questionId, answerTypeAnswers[0].answerTypeAnswerId)
        }
      />
    </Grid>
    <Grid item xs={4} key={answerTypeAnswers[1].answerTypeAnswerId}>
      <FontAwesomeIcon
        className={`${classes.answerType} ${noSelect}`}
        icon={faMeh}
        color={colors(1)}
        onClick={() =>
          sendAnswer(questionId, answerTypeAnswers[1].answerTypeAnswerId)
        }
        onKeyPress={() =>
          sendAnswer(questionId, answerTypeAnswers[1].answerTypeAnswerId)
        }
      />
    </Grid>
    <Grid item xs={4} key={answerTypeAnswers[2].answerTypeAnswerId}>
      <FontAwesomeIcon
        className={`${classes.answerType} ${noSelect}`}
        icon={faFrown}
        color={colors(2)}
        onClick={() =>
          sendAnswer(questionId, answerTypeAnswers[2].answerTypeAnswerId)
        }
        onKeyPress={() =>
          sendAnswer(questionId, answerTypeAnswers[2].answerTypeAnswerId)
        }
      />
    </Grid>
  </Grid>
);

export const AnswerTypeSmiley = withStyles(styles)(UnstyledAnswerTypeSmiley);

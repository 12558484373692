import { WithStyles, withStyles } from '@material-ui/core';
import { AnswerTypeType } from '@gts-ns/utils';
import { AnswerTypeComponentProps } from '../types';
import { AnswerTypeStars } from './AnswerTypes/AnswerTypeStars';
import { AnswerType1To6 } from './AnswerTypes/AnswerType1To6';
import { AnswerTypeSmiley } from './AnswerTypes/AnswerTypeSmiley';
import { AnswerTypeThumbs } from './AnswerTypes/AnswerTypeThumbs';
import { AnswerType1To10 } from './AnswerTypes/AnswerType1To10';
import { AnswerTypeCustom } from './AnswerTypes/AnswerTypeCustom';

const styles = () => ({
  noSelect: {
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
});

interface AnswersProps
  extends Omit<AnswerTypeComponentProps, 'noSelect'>,
    WithStyles<typeof styles> {
  answerType: AnswerTypeType;
}

function getAnswerType(
  sendAnswer: AnswersProps['sendAnswer'],
  questionId: AnswersProps['questionId'],
  answerType: AnswersProps['answerType'],
  answerTypeAnswers: AnswersProps['answerTypeAnswers'],
  noSelect: string,
) {
  switch (answerType) {
    case AnswerTypeType.ONE_TO_TEN:
      return (
        <AnswerType1To10
          questionId={questionId}
          answerTypeAnswers={answerTypeAnswers}
          sendAnswer={sendAnswer}
          noSelect={noSelect}
        />
      );
    case AnswerTypeType.ONE_TO_SIX:
      return (
        <AnswerType1To6
          questionId={questionId}
          answerTypeAnswers={answerTypeAnswers}
          sendAnswer={sendAnswer}
          noSelect={noSelect}
        />
      );
    case AnswerTypeType.STARS:
      return (
        <AnswerTypeStars
          questionId={questionId}
          answerTypeAnswers={answerTypeAnswers}
          sendAnswer={sendAnswer}
          noSelect={noSelect}
        />
      );
    case AnswerTypeType.SMILEYS:
      return (
        <AnswerTypeSmiley
          questionId={questionId}
          answerTypeAnswers={answerTypeAnswers}
          sendAnswer={sendAnswer}
          noSelect={noSelect}
        />
      );
    case AnswerTypeType.THUMBS:
      return (
        <AnswerTypeThumbs
          questionId={questionId}
          answerTypeAnswers={answerTypeAnswers}
          sendAnswer={sendAnswer}
          noSelect={noSelect}
        />
      );
    case AnswerTypeType.CUSTOM:
      return (
        <AnswerTypeCustom
          questionId={questionId}
          answerTypeAnswers={answerTypeAnswers}
          sendAnswer={sendAnswer}
          noSelect={noSelect}
        />
      );
    default:
      return null;
  }
}

const UnstyledAnswers = ({
  sendAnswer,
  questionId,
  answerType,
  answerTypeAnswers,
  classes,
}: AnswersProps) => (
  <div>
    <div>
      {getAnswerType(
        sendAnswer,
        questionId,
        answerType,
        answerTypeAnswers,
        classes.noSelect,
      )}
    </div>
  </div>
);

export const Answers = withStyles(styles)(UnstyledAnswers);

import { Box, Typography } from '@material-ui/core';
import { LinkButton } from '@gts-common/client';

interface Props {
  url: string;
  description: string;
  urlDescription: string;
  headline: string;
}

export const Link = ({ url, description, urlDescription, headline }: Props) => {
  return (
    <Box mt={5}>
      <Typography align="center" variant="h4">
        {headline}
      </Typography>
      <Box mt={5} mb={2}>
        <Typography align="center" variant="h6">
          {description}
        </Typography>
      </Box>
      <Box mt="1rem">
        <Typography align="center" variant="h6">
          <LinkButton
            external={true}
            to={url}
            label={urlDescription !== '' ? urlDescription : url}
            target="_self"
          />
        </Typography>
      </Box>
    </Box>
  );
};

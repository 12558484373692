"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnswerTypeType = exports.MAX_LINK_URL_DESCRIPTOR_LENGTH = exports.MAX_LINK_URL_LENGTH = exports.MAX_LINK_DESCRIPTOR_LENGTH = exports.MAX_LINK_HEADLINE_LENGTH = exports.MAX_THANK_YOU_TEXT_LENGTH = exports.MAX_CUSTOM_ANSWER_LENGTH = exports.MAX_DESCRIPTION_LENGTH = exports.MAX_QUESTION_LENGTH = exports.MAX_NAME_LENGTH = exports.WIDGET_SURVEY = exports.SURVEY_RESULTS = exports.SURVEY = exports.USERS_PATH = exports.LOGOUT_PATH = exports.LOGIN_PATH = exports.SurveyStatus = void 0;
var SurveyStatus;
(function (SurveyStatus) {
    SurveyStatus["PENDING"] = "PENDING";
    SurveyStatus["TERMINATED"] = "TERMINATED";
    SurveyStatus["ACTIVE"] = "ACTIVE";
})(SurveyStatus = exports.SurveyStatus || (exports.SurveyStatus = {}));
// paths
exports.LOGIN_PATH = '/login';
exports.LOGOUT_PATH = '/logout';
exports.USERS_PATH = '/users';
exports.SURVEY = '/survey';
exports.SURVEY_RESULTS = '/survey-results';
exports.WIDGET_SURVEY = '/widget/survey';
// Validations
exports.MAX_NAME_LENGTH = 50;
exports.MAX_QUESTION_LENGTH = 200;
exports.MAX_DESCRIPTION_LENGTH = 500;
exports.MAX_CUSTOM_ANSWER_LENGTH = 100;
exports.MAX_THANK_YOU_TEXT_LENGTH = 256;
exports.MAX_LINK_HEADLINE_LENGTH = 300;
exports.MAX_LINK_DESCRIPTOR_LENGTH = 600;
exports.MAX_LINK_URL_LENGTH = 200;
exports.MAX_LINK_URL_DESCRIPTOR_LENGTH = 40;
// Answer types
var AnswerTypeType;
(function (AnswerTypeType) {
    AnswerTypeType["ONE_TO_SIX"] = "1-TO-6";
    AnswerTypeType["STARS"] = "STARS";
    AnswerTypeType["SMILEYS"] = "SMILEYS";
    AnswerTypeType["ONE_TO_TEN"] = "1-TO-10";
    AnswerTypeType["THUMBS"] = "THUMBS";
    AnswerTypeType["CUSTOM"] = "CUSTOM";
})(AnswerTypeType = exports.AnswerTypeType || (exports.AnswerTypeType = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.okFailedResponseBodies = void 0;
exports.okFailedResponseBodies = {
    SURVEY_NOT_ACTIVE: {
        status: 1000,
        reason: 'Survey is not active',
    },
    LOGIN_TICKET_EXPIRED: { status: 1001, reason: 'Login ticket expired' },
};

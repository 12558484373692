import {
  Grid,
  Box,
  Typography,
  withStyles,
  Hidden,
  createStyles,
  Theme,
  WithStyles,
} from '@material-ui/core';
import { oneToTen as colors } from '@gts-ns/ui';
import { AnswerTypeComponentProps } from '../../types';

const styles = (theme: Theme) =>
  createStyles({
    answerType: {
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        fontSize: 100,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 120,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 200,
      },
    },
  });

type AnswerType1To10Props = AnswerTypeComponentProps &
  WithStyles<typeof styles>;

const UnstyledAnswerType1To10 = ({
  sendAnswer,
  questionId,
  answerTypeAnswers,
  classes,
  noSelect,
}: AnswerType1To10Props) => (
  <Grid container spacing={1}>
    <Grid item xs={1} />
    {answerTypeAnswers.map((answerTypeAnswer, i) => (
      <>
        {i === 5 && (
          <Hidden smUp>
            <Grid item xs={1} />
            <Grid item xs={1} />
          </Hidden>
        )}
        <Grid item xs={2} sm={1}>
          <Box
            className={`${classes.answerType} ${noSelect}`}
            pt={1}
            pb={1}
            border={1}
            borderRadius={5}
            borderColor="grey.500"
            bgcolor={colors(i)}
            key={answerTypeAnswer.answerTypeAnswerId}
            onClick={() =>
              sendAnswer(questionId, answerTypeAnswer.answerTypeAnswerId)
            }
            onKeyPress={() =>
              sendAnswer(questionId, answerTypeAnswer.answerTypeAnswerId)
            }
            role="link"
          >
            <Typography align="center" variant="h4" className={noSelect}>
              {answerTypeAnswer.label}
            </Typography>
          </Box>
        </Grid>
      </>
    ))}
  </Grid>
);

export const AnswerType1To10 = withStyles(styles)(UnstyledAnswerType1To10);

import ReactDOM from 'react-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import {
  GlobalErrorCatcher,
  serviceTheme,
  setupGlobalErrorHandler,
} from '@gts-common/client';

import { Provider } from 'react-redux';
import { serverComm } from '@gts-ns/ui';
import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';
import { AppContainer } from './container/App';
import { getSurvey } from './actions';
import { store } from './store';
import logo from './images/logo.png';

setupGlobalErrorHandler(serverComm, store);

// Redux doesn't understand thunk actions
// Convince it that it is okay to use that here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
store.dispatch(getSurvey() as any);

const Root = () => (
  <GlobalErrorCatcher
    serverComm={serverComm}
    headerTitle="next-survey"
    logo={logo}
    domain={getUrl(ServiceCode.NEXT_SURVEY, UrlType.FRONT_PAGE)}
  >
    <CssBaseline />
    <Provider store={store}>
      <ThemeProvider theme={serviceTheme}>
        <AppContainer />
      </ThemeProvider>
    </Provider>
  </GlobalErrorCatcher>
);

ReactDOM.render(<Root />, document.getElementById('root'));

import {
  AnswerTypeType,
  ConditionalLink,
  WidgetGetSurveyResponseConditionalQuestion,
  WidgetGetSurveyResponse,
} from '@gts-ns/utils';
import { CommonState } from '@gts-common/client';

export interface AnswerTypeAnswer {
  answerTypeAnswerId: string;
  label: string;
  hint: string;
}

export interface AnswerTypeComponentProps {
  sendAnswer: (questionId: string, answerTypeAnswerId: string) => void;
  questionId: string;
  answerTypeAnswers: Array<AnswerTypeAnswer>;
  noSelect: string;
}

export interface ClientAnswerTypeAnswer {
  answerTypeAnswerId: string;
  label: string;
  hint: string;
  conditionalQuestion: WidgetGetSurveyResponseConditionalQuestion | null;
  conditionalLink: ConditionalLink | null;
}

export interface QuestionType {
  questionId: string;
  question: string;
  description: string;
  answerType: AnswerTypeType;
  answers: Array<ClientAnswerTypeAnswer>;
}

export interface SurveyAnswer {
  answerTypeAnswerId: string;
  questionId: string;
}

export interface AppState {
  survey: WidgetGetSurveyResponse | undefined;
  activeQuestion:
    | QuestionType
    | WidgetGetSurveyResponseConditionalQuestion
    | undefined;
  conditionalLink: ConditionalLink | undefined;
  surveyWidgetStatus: SurveyWidgetStatus;
  mainQuestionAnswer: SurveyAnswer | undefined;
}

export interface ReduxState {
  app: AppState;
  common: CommonState;
}

export enum SurveyWidgetStatus {
  PENDING = 'PENDING',
  INVALID_LINK = 'INVALID_LINK',
  NOT_FOUND = 'NOT_FOUND',
  NOT_ACTIVE = 'NOT_ACTIVE',
  MAIN_QUESTION = 'MAIN_QUESTION',
  CONDITIONAL_QUESTION = 'CONDITIONAL_QUESTION',
  CONDITIONAL_LINK = 'CONDITIONAL_LINK',
  ANSWERED = 'ANSWERED',
}

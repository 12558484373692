import { Box, Typography } from '@material-ui/core';
import { CancelButton } from '@gts-common/client';
import { AnswerTypeType } from '@gts-ns/utils';
import { AnswerTypeComponentProps } from '../types';
import { Answers } from './Answers';

interface Props extends Omit<AnswerTypeComponentProps, 'noSelect'> {
  question: string;
  description: string;
  answerType: AnswerTypeType;
  // Only needed for a conditional question
  onCancel?: () => void;
  isFollowUpQuestion: boolean;
}

export const Question = ({
  questionId,
  question,
  description,
  answerType,
  answerTypeAnswers,
  sendAnswer,
  onCancel,
  isFollowUpQuestion,
}: Props) => {
  return (
    <Box mt={5}>
      <Typography align="center" variant="h4">
        {isFollowUpQuestion ? 'Folgefrage:' : ''} {question}
      </Typography>
      <Box mt={5} mb={2}>
        <Typography align="center" variant="h6">
          {description}
        </Typography>
      </Box>

      <Answers
        questionId={questionId}
        answerTypeAnswers={answerTypeAnswers}
        answerType={answerType}
        sendAnswer={sendAnswer}
      />

      {isFollowUpQuestion && onCancel && (
        <Box textAlign="center" mt="6rem">
          <CancelButton label="Keine Auswahl" onClick={onCancel} />
        </Box>
      )}
    </Box>
  );
};

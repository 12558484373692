import { WidgetGetSurveyResponse } from '@gts-ns/utils';
import { initialState } from '../initialState';
import {
  Actions,
  DISPLAY_ANSWERED,
  DISPLAY_CONDITIONAL_LINK,
  DISPLAY_CONDITIONAL_QUESTION,
  GET_SURVEY_SUCCEEDED,
  LINK_INVALID,
  SURVEY_NOT_ACTIVE,
  SURVEY_NOT_FOUND,
} from '../actions/reduxActionTypes';
import { AppState, SurveyWidgetStatus } from '../types';

function getMainSurveyQuestion(survey: WidgetGetSurveyResponse) {
  return {
    questionId: survey.questionId,
    question: survey.question,
    description: survey.description,
    answerType: survey.answerType,
    answers: survey.answers,
  };
}

export function appReducer(
  state = initialState.app,
  action: Actions,
): AppState {
  switch (action.type) {
    case GET_SURVEY_SUCCEEDED:
      return {
        ...state,
        survey: action.payload,
        activeQuestion: getMainSurveyQuestion(action.payload),
        surveyWidgetStatus: SurveyWidgetStatus.MAIN_QUESTION,
      };

    case SURVEY_NOT_FOUND:
      return {
        ...state,
        surveyWidgetStatus: SurveyWidgetStatus.NOT_FOUND,
      };

    case SURVEY_NOT_ACTIVE:
      return {
        ...state,
        surveyWidgetStatus: SurveyWidgetStatus.NOT_ACTIVE,
      };

    case LINK_INVALID:
      return {
        ...state,
        surveyWidgetStatus: SurveyWidgetStatus.INVALID_LINK,
      };

    case DISPLAY_CONDITIONAL_QUESTION:
      return {
        ...state,
        activeQuestion: action.payload.conditionalQuestion,
        surveyWidgetStatus: SurveyWidgetStatus.CONDITIONAL_QUESTION,
        mainQuestionAnswer: action.payload.mainQuestionAnswer,
      };

    case DISPLAY_CONDITIONAL_LINK:
      return {
        ...state,
        surveyWidgetStatus: SurveyWidgetStatus.CONDITIONAL_LINK,
        conditionalLink: action.payload,
      };

    case DISPLAY_ANSWERED:
      return {
        ...state,
        surveyWidgetStatus: SurveyWidgetStatus.ANSWERED,
      };

    default:
      return state;
  }
}

import Rainbow from 'rainbowvis.js';
import { AnswerTypeType } from '@gts-ns/utils';
const green = '#14Bf00';
const yellow = '#D3CC00';
const red = '#E52D00';
const gray = '#9e9e9e';
const lighterGreen = '#33C200';
const deepOrange = '#E14F00';
const blue = '#2F7BC9';
const lightBlue = '#00ACEE';
const orange = 'orange';
function initColors(numColors, spectrum) {
    const rainbow = new Rainbow();
    const [color1, color2, ...colors] = spectrum;
    rainbow.setSpectrum(color1, color2, ...colors);
    rainbow.setNumberRange(0, numColors - 1);
    return (num) => `#${rainbow.colourAt(num)}`;
}
export const filledStar = yellow;
export const emptyStar = gray;
export const smileys = initColors(3, [lighterGreen, yellow, deepOrange]);
export const grades = initColors(6, [green, yellow, red]);
export const stars = initColors(5, [red, yellow, green]);
export const thumbs = (num) => (num === 0 ? deepOrange : lighterGreen);
export const oneToTen = initColors(10, [red, yellow, green]);
export const custom = (numColors) => initColors(numColors, [orange, yellow, green, blue, lightBlue]);
// numColors will only be used in custom
export function getColors(answerType, numColors) {
    switch (answerType) {
        case AnswerTypeType.ONE_TO_SIX:
            return grades;
        case AnswerTypeType.STARS:
            return stars;
        case AnswerTypeType.SMILEYS:
            return smileys;
        case AnswerTypeType.ONE_TO_TEN:
            return oneToTen;
        case AnswerTypeType.THUMBS:
            return thumbs;
        case AnswerTypeType.CUSTOM:
            return numColors > 1 ? custom(numColors) : () => 'white';
        default:
            return () => 'blue';
    }
}

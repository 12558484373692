import {
  Grid,
  withStyles,
  Box,
  Theme,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  filledStar as filledStarColor,
  emptyStar as emptyStarColor,
} from '@gts-ns/ui';
import { AnswerTypeComponentProps } from '../../types';

const styles = (theme: Theme) =>
  createStyles({
    answerType: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 23,
      },
    },
  });

type AnswerTypeStarsProps = AnswerTypeComponentProps &
  WithStyles<typeof styles>;

const UnstyledAnswerTypeStars = ({
  sendAnswer,
  questionId,
  answerTypeAnswers,
  classes,
  noSelect,
}: AnswerTypeStarsProps) => (
  <Grid container>
    <Grid item xs={1} />
    {answerTypeAnswers.map((answerTypeAnswer, answerIndex) => (
      <Grid item xs={2} key={answerTypeAnswer.answerTypeAnswerId}>
        <Box
          textAlign="center"
          border={0.3}
          borderColor="grey.500"
          m="0.3rem"
          pt="3rem"
          pb="3rem"
          boxShadow={2}
          borderRadius="borderRadius"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            sendAnswer(questionId, answerTypeAnswer.answerTypeAnswerId)
          }
          onKeyPress={() =>
            sendAnswer(questionId, answerTypeAnswer.answerTypeAnswerId)
          }
        >
          {[0, 1, 2, 3, 4].map((value, index) => {
            return (
              <FontAwesomeIcon
                key={value}
                className={`${classes.answerType} ${noSelect}`}
                icon={index <= answerIndex ? solidStar : emptyStar}
                color={index <= answerIndex ? filledStarColor : emptyStarColor}
              />
            );
          })}
        </Box>
      </Grid>
    ))}
  </Grid>
);

export const AnswerTypeStars = withStyles(styles)(UnstyledAnswerTypeStars);

import { Box, Typography } from '@material-ui/core';
import {
  WarningText,
  PleaseWaitText,
  UnknownLinkText,
  InvalidLinkText,
} from '@gts-common/client';
import { ConditionalLink, WidgetGetSurveyResponse } from '@gts-ns/utils';
import { QuestionType, SurveyWidgetStatus } from '../types';
import { surveyInactiveMessage } from '../validationMessages';
import { Question } from './Question';
import { Link } from './Link';

export function getMainView(
  surveyWidgetStatus: SurveyWidgetStatus,
  survey: WidgetGetSurveyResponse | undefined,
  activeQuestion: QuestionType | undefined,
  conditionalLink: ConditionalLink | undefined,
  sendAnswer: (questionId: string, answerTypeAnswerId: string) => void,
  onCancel: () => void,
) {
  if (surveyWidgetStatus === SurveyWidgetStatus.NOT_FOUND) {
    return <UnknownLinkText />;
  } else if (surveyWidgetStatus === SurveyWidgetStatus.INVALID_LINK) {
    return <InvalidLinkText />;
  } else if (surveyWidgetStatus === SurveyWidgetStatus.NOT_ACTIVE) {
    return <WarningText>{surveyInactiveMessage}</WarningText>;
  } else if (
    surveyWidgetStatus === SurveyWidgetStatus.MAIN_QUESTION &&
    activeQuestion
  ) {
    return (
      <Question
        questionId={activeQuestion.questionId}
        question={activeQuestion.question}
        description={activeQuestion.description}
        answerType={activeQuestion.answerType}
        answerTypeAnswers={activeQuestion.answers}
        sendAnswer={sendAnswer}
        isFollowUpQuestion={false}
      />
    );
  } else if (
    surveyWidgetStatus === SurveyWidgetStatus.CONDITIONAL_QUESTION &&
    activeQuestion
  ) {
    return (
      <Question
        questionId={activeQuestion.questionId}
        question={activeQuestion.question}
        description={activeQuestion.description}
        answerType={activeQuestion.answerType}
        answerTypeAnswers={activeQuestion.answers}
        sendAnswer={sendAnswer}
        onCancel={onCancel}
        isFollowUpQuestion={true}
      />
    );
  } else if (
    surveyWidgetStatus === SurveyWidgetStatus.CONDITIONAL_LINK &&
    conditionalLink
  ) {
    return (
      <Link
        url={conditionalLink.url}
        urlDescription={conditionalLink.urlDescription}
        headline={conditionalLink.headline}
        description={conditionalLink.description}
      />
    );
  } else if (surveyWidgetStatus === SurveyWidgetStatus.ANSWERED && survey) {
    return (
      <>
        <Box mt={5}>
          <Typography align="center" variant="h4">
            {survey.thankYouText}
          </Typography>
        </Box>
      </>
    );
  } else {
    return (
      <Box mt="2rem">
        <PleaseWaitText />
      </Box>
    );
  }
}

import { Grid, Box, Typography, withWidth, WithWidth } from '@material-ui/core';
import { grades as colors } from '@gts-ns/ui';
import { AnswerTypeAnswer, AnswerTypeComponentProps } from '../../types';

interface SingleAnswerProps
  extends Omit<AnswerTypeComponentProps, 'answerTypeAnswers'> {
  answerTypeAnswer: AnswerTypeAnswer;
  isSmall: boolean;
  color: string;
}

const SingleAnswer = ({
  sendAnswer,
  questionId,
  answerTypeAnswer,
  isSmall,
  color,
  noSelect,
}: SingleAnswerProps) => (
  <Box
    pt={isSmall ? 0.5 : 4}
    pb={isSmall ? 0.5 : 4}
    border={1}
    borderRadius={5}
    borderColor="grey.500"
    bgcolor={color}
    style={{
      cursor: 'pointer',
    }}
    key={answerTypeAnswer.answerTypeAnswerId}
    onClick={() => sendAnswer(questionId, answerTypeAnswer.answerTypeAnswerId)}
    onKeyPress={() =>
      sendAnswer(questionId, answerTypeAnswer.answerTypeAnswerId)
    }
    role="link"
    className={noSelect}
  >
    <Typography
      align="center"
      variant={isSmall ? 'h6' : 'h2'}
      className={noSelect}
    >
      {answerTypeAnswer.label}
    </Typography>
  </Box>
);

const SmallAnswers = ({
  sendAnswer,
  questionId,
  answerTypeAnswers,
  noSelect,
}: AnswerTypeComponentProps) => (
  <>
    <Box textAlign="center" className={noSelect}>
      <Typography variant="h6">{answerTypeAnswers[0].hint}</Typography>
    </Box>
    <Grid container spacing={1}>
      {answerTypeAnswers.map((answerTypeAnswer, i) => (
        <>
          <Grid item xs={3} />
          <Grid item xs={6} sm={2}>
            <SingleAnswer
              color={colors(i)}
              sendAnswer={sendAnswer}
              questionId={questionId}
              answerTypeAnswer={answerTypeAnswer}
              isSmall
              noSelect={noSelect}
            />
          </Grid>
          <Grid item xs={3} />
        </>
      ))}
    </Grid>
    <Box textAlign="center" className={noSelect}>
      <Typography variant="h6">{answerTypeAnswers[5].hint}</Typography>
    </Box>
    <Box padding="15px" />
  </>
);

const LargeAnswers = ({
  sendAnswer,
  questionId,
  answerTypeAnswers,
  noSelect,
}: AnswerTypeComponentProps) => (
  <>
    <Grid container spacing={1}>
      {answerTypeAnswers.map((answerTypeAnswer, i) => (
        <>
          <Grid item sm={2}>
            <SingleAnswer
              color={colors(i)}
              sendAnswer={sendAnswer}
              questionId={questionId}
              answerTypeAnswer={answerTypeAnswer}
              isSmall={false}
              noSelect={noSelect}
            />
            <Box textAlign="center" className={noSelect}>
              {(i === 5 || i === 0) && (
                <Typography variant="h6">
                  {answerTypeAnswers[i].hint}
                </Typography>
              )}
            </Box>
          </Grid>
        </>
      ))}
    </Grid>
    <Box padding="15px" />
  </>
);

type AnswerType1To6Props = AnswerTypeComponentProps & WithWidth;

const WithoutWidthAnswerType1To6 = ({
  sendAnswer,
  questionId,
  answerTypeAnswers,
  width,
  noSelect,
}: AnswerType1To6Props) => {
  const isSmall = width === 'xs';
  return isSmall ? (
    <SmallAnswers
      sendAnswer={sendAnswer}
      answerTypeAnswers={answerTypeAnswers}
      questionId={questionId}
      noSelect={noSelect}
    />
  ) : (
    <LargeAnswers
      sendAnswer={sendAnswer}
      answerTypeAnswers={answerTypeAnswers}
      questionId={questionId}
      noSelect={noSelect}
    />
  );
};

export const AnswerType1To6 = withWidth()(WithoutWidthAnswerType1To6);
